body {
  font-family: -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  padding: 0;
  margin: 0;
  background-color: #151a29;
  color: #fff;
  font-size: 14px;
  height: 100vh;
  overflow-y: hidden;
}

li,
p {
  line-height: 1.5rem;
}

fieldset{
  border: 0;
  padding: 0;
  margin: 0;
  outline: 0;
}

a {
  position: relative;
  color: inherit;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}
a:hover {
  opacity: 0.9;
}
a.link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  z-index: -1;
  left: 0;
  background: -o-linear-gradient(138.92deg, #c2a633 -12.19%, #ebcc4c 97.82%);
  background: linear-gradient(
311.08deg
, #c2a633 -12.19%, #ebcc4c 97.82%);
  border-radius: 2px;
}

hr {
  border: 1px solid #ddd;
}

iframe {
  background: #ccc;
  border: 1px solid #ccc;
  height: 10rem;
  width: 100%;
  border-radius: .5rem;
  filter: invert(1);
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(0 0 0 / 10%);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgb(255 255 255 / 15%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#__next{
  height: 100%;
}
.container{
  display: grid;
  height: 100%;
  grid-template-areas:
    'navArea headerArea'
    'navArea mainArea';
  grid-template-columns: 72px auto;
  grid-template-rows: 72px auto;
  height: 100vh;
  width: 100vw;
}

header{
  grid-area: headerArea;
}
nav{
  grid-area: navArea;
  background-color: #1f2536;
}
main{
  grid-area: mainArea;
  display:  flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-y: hidden;
}

main.recordings{
  flex-direction: column;
}

.searchInput{
  padding: 1em;
  width: 350px;
  max-width: 100%;
}
.scroll{
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  justify-content: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 480px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.section-live--controls .scroll{
  display: block;
}
.button,
.buttonPrimary {
  margin: 0 1em;
  font-weight: 500;
  border-radius: .3rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.4rem;
  padding: .7rem .8rem;
  position: relative;
  background-color: transparent;
  color: #151a29;
}

.button.delete{
  height: 73px;
  background-color: #8c2e2e;
}
.button.delete.delete-choice{
  height: auto;
  background-color: transparent;
  padding: 8px;
}

.buttonPrimary {
  background-color: #ebcc4c;
  border: 1px solid #ebcc4c;
  text-decoration: none;
  padding: .7rem 1.4rem;
}

.buttonPrimary:hover {
  box-shadow: inset 0 0 5rem rgba(0,0,0,0.2);
  color: #fff;
  background: #151a29;
  border-color: #fff;
}

button.primary {
  background-color: #ebcc4c;
  border: 1px solid #ebcc4c;
  text-decoration: none;
  margin-right: 0.4rem;
  padding: .7rem 1.4rem;
  font-weight: 500;
  border-radius: .3rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.4rem;
  padding: .7rem .8rem;
  color: #151a29;
}

button.primary:hover {
  box-shadow: inset 0 0 5rem rgba(0,0,0,0.2);
  color: #fff;
  background: #151a29;
  border-color: #fff;
}

.form {
  max-width: 800px;
  margin: 0 auto;
}

textarea,
select,
input {
  resize: none;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  color: #fff;
  background-color: #161a26;
  border: 1px solid #333;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.label,
section > label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

input[type="reset"],
input[type="submit"],
.button {
  cursor: pointer;
  background: #516391;
  color: #ccc;
  text-transform: uppercase;
  border: none;
  margin-top: 20px;
  padding: 15px;
  font-weight: 100;
  letter-spacing: 3px;
  display: block;
  appearance: none;
  border-radius: 4px;
  width: 100%;
  font-weight: lighter;
}
input[type="radio"]{
  margin:12px 0;
  min-width: 22px;
}
label.noCorrect{
  line-height: 40px;
}

form button[type="button"],
form .button{
  margin:0;
}

input[type="submit"],
button[type="button"].primary,
.button.primary{
  background: #2e602f;
}

.button:disabled,
input[type="reset"]:disabled,
input[type="submit"]:disabled,
button[type="button"]:disabled {
  opacity: 0.75;
  pointer-events: none;
}

.button:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
button[type="button"]:hover {
  box-shadow: inset 0 0 5rem rgba(255,255,255,0.2);
  color: #fff;
}
label{
  line-height: 28px;
  vertical-align: text-bottom;
  display: inline-block;
  padding-left: 1em;
}
input[type="checkbox"]{
  -webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
  display: inline-block;
	position: relative;
	border: 0;
	outline: 0;
	cursor: pointer;
	margin: 10px;
  background: none;
  width: auto;
  padding: 0;
}
input[type="checkbox"]:after{
  content: '';
	width: 40px;
	height: 18px;
	display: inline-block;
	background: rgba(196, 195, 195, 0.55);
	border-radius: 18px;
	clear: both;
}
input[type="checkbox"]:before{
  content: '';
	width: 22px;
	height: 22px;
	display: block;
	position: absolute;
	left: 0;
	top: -2px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}
input[type="checkbox"]:checked:before{
  left: 18px;
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
}
input[type="checkbox"]:checked:after{
  background: #2e602f;
}
.card-grid{
  display: flex;
  flex-wrap: wrap;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
  overflow-y: auto;
  height: 100%;
  align-content: flex-start;
}

.card{
  padding: 1em;
  margin: 1em;
  background-color: #fff3;
  border-radius: 5px;
  height: 110px;
  position: relative;
  box-sizing: border-box;
}
.card-recording{
  width:350px;
  display: flex;
  align-items: center;
}
.card-recording .image{
  padding-right: 1em;
}
.card-recording .trackType{
  padding-left: 1em;
}
.card.card-new{
  background-color: #429242;
  cursor: pointer;
}
.details{
  font-weight: normal;
  font-size: 14px;
  margin-top: 0.5em;
  color: #ddd;
  text-transform: uppercase;
}
.details dl{
  margin:0;
}

.details dt{
  clear: left;
  float: left;
  width: 42px;
  font-size: x-small;
  text-align: right;
  color: #999;
  font-weight: bold;
  line-height: 19px;
}
.details dd{
  line-height: 19px;
  padding-left: 7px;
  letter-spacing: 1px;
  font-size: small;
  font-family: monospace;
}

.card .primary-action{
  position: absolute;
  bottom:0;
  right: 0;
  padding: 1em;
  cursor: pointer;
}
.card .primary-action .delay{
  padding-left: 3px;
}
.card .secondary-action{
  position: absolute;
  top:0;
  right: 0;
  padding: 1em;
  cursor: pointer;
}
.card .secondary-action.media{
  top:24px;
}

.card .actions{
  position: absolute;
  right: -22px;
  top: -22px;
  display: flex;
  flex-direction: column;
}

.actions .action{
  background: rgb(56, 54, 54);
  padding: 10px;
  border-radius: 50%;
}

.input-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-row > .input-group {
  flex-basis: 50%;
}
.input-row > .input-group.shrink {
  flex-basis: 5%;
}
.input-row > .input-group.wide {
  flex-basis: 85%;
}
.input-row > .input-group.narrow {
  flex-basis: 15%;
}
.input-row > .input-group.wide.choices input {
  width: 47.5%;
  float: left;
  margin-right: 2.5%;
}
.input-group{
  padding-bottom: 1em;
  position: relative;
  flex-grow: 1;
  margin: 0 1em;;
}
.input-group .errors{
  position: absolute;
  font-size: smaller;
  background-color: #53222240;
  color:#ca9494;
  width: 100%;
  bottom: 5px;
  box-sizing: border-box;
  border-radius: 5px;
}
.input-group .errors .error{
  margin:5px 10px;
  display: inline-block;
}

.card-loading{
  opacity: 0.5;
  transition: all 1s ease;
}

.card-loading svg {
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.section-polls{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  /* outline:6px solid #121623;
  margin: 6px 0; */
  display: flex;
  flex-direction: column;
  min-width: 430px;
}
.section-polls.live{
  border-color: #ebcc4c;
}
hr {
  width: 100%;
  border: 1px dashed #1f2536;
  box-sizing: border-box;
  overflow: initial;
  position: relative;
}
hr.divider::after{
  position: absolute;
  text-align: center;
  top: -7px;
  font-size: 10px;
  letter-spacing: 4px;
  color: #455278;
  left: 50%;
  transform: translateX(-50%);
  background: #151a29;
  padding: 0 5px;
}
hr.divider.draft::after{
  content:"DRAFTS";
}
hr.divider.completed::after{
  content:"COMPLETED";
}
.section-live {
  width: 100%;
  background: #0005;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow-x: hidden;
  min-width: 400px;
}
.section-live.section-live--data{
  box-sizing: border-box;
  border-left: 1px dashed #1f2536;
  min-width: 360px;
}
@media only screen and (min-width: 600px) {
  .container{
    grid-template-columns: 72px auto;
    grid-template-rows: 72px auto;
  }
  main{
    flex-direction: row;
  }
  main.control{
    flex-direction: column;
    overflow-y: auto;
  }
  .section-polls,
  .section-live{
    height: 100%;
  }
}
.section-live .details{
  position: absolute;
}
.card.card-poll{
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  height: 100px;
  min-height: 100px;
  font-size: small;
  align-items: center;
  padding: 0;
  margin: 5px 0;
  position: relative;
}
.card.card-poll.dragging{
  opacity: 0.25;
}
.card.card-poll.dropping{
  opacity: 0.75;
}
.card.card-poll.dropping::before{
  content: "";
  display: block;
  width: 100%;
  height: 5px;
  background: #516391;
  top: -7px;
  position: absolute;
  border-radius: 2px;
}
.card.card-poll.card-loading,
.card.card-poll.card-new{
  padding: 1em;
}
.card.card-poll.selected{
  background-color: #47598c;
}
.card.card-poll.live{
  /* pointer-events: none; */
  background-color: #ad7c0d;
  order: -1;
}
.card.card-poll.completed{
  /* pointer-events: none; */
  opacity: 0.35!important;
  order: 2;
}
.card-grid.card-grid--poll-list{
  flex-direction: column;
  flex-wrap: nowrap;
  padding:10px
}
.card-grid .card.card-poll.completed:first-child{
  content: "";
  border-top: 1px dashed #ad7c0d;
  width: 100%;
  position: absolute;
  top: -10px;
  left: 0;
}
.card.card-poll.live::before,
.card.card-poll.live::after{
  /* content: ""; */
  border-top: 1px dashed #ad7c0d;
  width: 100%;
  position: absolute;
  top: -10px;
  left: 0;
}
.card.card-poll.live::after{
  top:auto;
  bottom:-10px;
}
.card .title{
  overflow: hidden;
  /* text-overflow: ellipsis; */
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 2; */
  /* -webkit-box-orient: vertical; */
  margin: 0;
  width: 346px;
  flex-shrink: 0;
}
.card.card-poll .title{
  max-width: 50%;
}
.card.card-poll .grabber{
  padding: 2em;
  cursor: move;
}
.card.card-poll.completed .grabber{
  cursor:pointer;
}

.choice-grid{
  padding-left: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: x-small;
  overflow:hidden;
  align-content: center;
  justify-content: flex-start;
  height: 100%;
}

.choice-grid > div{
  padding: 3px 10px;
  background-color: #0003;
  margin: 0 0.25em 0.25em;
  border-radius: 10px;
  height: fit-content;
}
.choice-grid > div.correct{
  background-color: #429242;
}

section.middle{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  text-align: center;
}

.episode-details{
  padding: 1em;
  display: flex;
  justify-content: space-between;
  border:6px solid transparent;
}
.live .episode-details{
  border-color: #ebcc4c;
}

.episode-actions{
  display: flex;
  align-items: center;
}
.episode-actions .action{
  text-align: center;
  font-size: 14px;
  padding: 0.5em 1em;
  margin-left: 0.35em;
  cursor: pointer;
  background: #ebcc4c;
  color: #fff;
  border: 0;
  outline: 0;
  border-radius: 5px;
  box-sizing: border-box;
  height: 36px;
  vertical-align: bottom;
}
.episode-actions .control,
.episode-actions .action.share{
  background-color: #47598c;
}
.episode-actions .action:hover{
  background-color: #429242;
}
.episode-actions.disabled .step,
.episode-actions .action:disabled{
  color: #444854;
  background-color: #0e111b;
  pointer-events: none;
}

.userList{
  background-color: #000e;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1em;
  height: 50%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-left: 9em;
}
.userList .user{
  position: relative;
}
.userList .totals{
  font-size: 10px;
  border-bottom: 1px dashed #1f2536;
  padding-bottom: 1em;
  margin-bottom: 1em;
}
.userList .user.admin::before{
  content: "ADMIN";
  position: absolute;
  left:-50px;
}
/* .userList .user .status{
  position: absolute;
  top: 4px;
  left: -12px;
  width: 6px;
  height: 6px;
  background-color: red;
  border-radius: 5px;
} */
.user-status .status{
  width: 20px;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  border-radius: 6px;
}
.user-status .status.online.video.disabled{
  color: lightgreen;
}
.user-status .status.online.video.enabled{
  color: green;
}
.user-status .status.offline{
  color: red;
}
.user-status .uid{
  padding-left: 1em;
  width: 30px;
}

.poll-leaderboard-list{
  margin: 1em auto;
  width: 100%;
  padding: 1em;
  max-width: 510px;
  background-color: #151a29;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 0.825em;
  text-align: left;
}
.poll-leaderboard-list th{
  border-bottom: 1px solid #fff;
  padding: 10px 5px;
}
.poll-leaderboard-list .rank,
.poll-leaderboard-list .response,
.poll-leaderboard-list .points{
  text-align: right;
}

.poll-leaderboard-list .footer{
  text-align: center;
  padding-top: 10px;
}
.poll-leaderboard-list .footer span{
  padding:5px;
}

.poll-leaderboard-list .player{ width:100%; text-transform: capitalize; }
.poll-leaderboard-list .rank{ width:40px; }
.poll-leaderboard-list .choice{ width:40px; }
.poll-leaderboard-list .points{ width:40px; }

.leaderboard-item > td{
  border-bottom: 1px solid #fff3;
  padding: 10px 5px;
}
.leaderboard-item > td.choice{
  text-align: center;
}

.live-steps{
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed #1f2536;
  margin-bottom: 10px;
}

.live-steps .step{
  text-align: center;
  flex-grow: 1;
  font-size: 20px;
  margin: 10px;
  padding: 1em;
  cursor: pointer;
  background: #151a29;
  color: #fff;
  border: 0;
  outline: 0;
  border-radius: 5px;
}
.live-steps .step{
  width: 60px;
}

.live-steps .step:hover{
  background: #47598c;
}
.live-steps .step.active{
  background: #ad7c0d;
}

.live-steps.disabled .step,
.live-steps .step:disabled{
  color: #444854;
  pointer-events: none;
}

.input-group .react-datepicker-wrapper{
  display: block;
}
.input-group .react-datepicker__close-icon{
  height: 38px;
}

.spin{
  margin: 1em;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
.agoraContainer{
  background: #0b0d14;
  height: 0;
  padding: 56.25% 0 0 0;
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  transition: opacity 1s ease-in-out;
}
.star-bg .agoraContainer{
  padding: 0;
  height: 100%;
}
.agoraContainer.disabled{
  opacity:0.15;
}
.agoraContainer.vid-star{
  width:100%
}
.videoWall .agoraContainer{
  width:100%;
  height:100%;
  padding:0;
  margin:0;
  /* border:5px solid transparent; */
  box-sizing: border-box;
}
.miniPlayer{
  width: 120px;
  position: absolute;
  z-index: 99;
  right: 0;
}
.miniPlayer .agoraContainer{
  background-color: #151a29;
}
.joinContainer,
.agoraInner{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.joinContainer button{
  max-width: 190px;
  margin: 0;
  font-size: smaller;
}
.videosContainer{
  width: 100%;
  height: 100%;
  position: relative;
}
.videosContainer .vid{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}
.videosContainer .star-extra{
  position: absolute;
  bottom: 30px;
  left:0;
  z-index: 1;
  width: 100%;
  height: 90px;
  background: url(/img/star_caption.png) no-repeat;
  background-position: center;
  /* mix-blend-mode: screen; */
  opacity: 0;
  transition: opacity 1s ease 1s;
}
.videosContainer .star-extra.show{
  opacity: 1;
}
.videosContainer .star-extra .name{
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 30px;
  font-size: 36px;
  color: #241d1b;
  font-weight: bold;
  text-transform: uppercase;
  mix-blend-mode: normal;
}
.video-extra{
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 8px;
  letter-spacing: 1px;
  /* opacity: 0.25; */
  padding: 5px 3px;
  z-index: 1;
}
.gridBox .video-extra{
  bottom: 0;
  right: 0;
  left: auto;
  text-align: right;
  text-shadow: 1px 1px black;
}
.video-extra span{
  padding: 3px 5px;
}
.video-extra span.name{
  background-color: #000;
  text-transform: uppercase;
}
.video-extra span.meta{
  display: none;
}
.agoraContainer .controls{
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  font-size: 20px;
}
.agoraContainer .controls span{
  text-align: center;
  padding: 5px;
  cursor: pointer;
  display: inline-block;
  color: #fff;
  background: #00000024;
  border-radius: 5px;
  margin: 3px;
  box-sizing: border-box;
  width: 48px;
}
.agoraContainer .controls span:hover{
  background: #00000099;
}
.debug-uid{
  position: absolute;
  z-index: 9;
  background: #000;
  padding: 5px 10px;
  right: 0;
}
.floatVid{
  position: absolute;
  width: 50%;
  top:50%;
  right:0;
  transform: translateY(-50%);
}

section.middle.middle--live{
  flex-direction: row;
}
section.middle.middle--live .floatVid{
  order: 1;
}

@media only screen and (max-width: 960px) {
  section.middle.middle--live .floatVid{
    /* position: absolute; */
    width: 240px;
    order: -1;
  }
  section.middle.middle--live{
    margin: 0;
    width: 100%;
    flex-direction: column;
  }
}
.host-mute{
  position: absolute;
  z-index: 1;
  padding: 1em;
  font-size: 30px;
}
.poll-media-image{
  width: 100%;
  position: absolute;
  height: auto;
  left: 0;
  top: 0;
}

.poll-media-image--host{
  width:25%;
  height: auto;
  left:auto;
  top:auto;
  right: 0;
  bottom: 0;
}

.poll-media-image--guest{
  position: relative;
}

#ReactSimpleImageViewer{
  padding: 0;
}

.videoWall{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  width: 100%;
  height: 100%;
}
.videoWall.videoWall-16{
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
.videoWall.videoWall-20{
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
.videoWall.videoWall-20{
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
}
.videoWall.videoWall-30{
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 1fr);
}
.gridBox{
  outline:1px dotted #0003;
  position: relative;
  text-align:center;
  box-sizing: border-box;
  /* background: url(/img/default-user.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 5px solid #141826;
}
.gridBox::before{
  content: attr(data-order);
  font-size: 10px;
  color: #fff1;
  text-transform: uppercase;
  position: absolute;
  left:50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%,-50%);
  z-index: 1;
}
.user-control-grid-rows{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.user-control-grid{
  user-select: none;
  width: 100%;
  display: flex;
}
.user-control-grid .screen-rows{
  flex-grow: 1;
}
.user-control-group{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin: 20px 5px 10px;
  position: relative;
  /* min-height: 46px; */
  border-top:1px dotted #444;
  border-left:1px dotted #444;
}
.user-control-group.user-control-group-16{
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
.user-control-group.user-control-group-20{
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
.user-control-group.user-control-group-20{
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
}
.user-control-group.user-control-group-30{
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 1fr);
}
.user-control-group.star{
  border: 1px dashed #444;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#444;
  flex-basis: 15%;
  text-align: center;
}

.stars-container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.star-details{
  background-color: #364d36;
  border: 1px dashed #eee;
  padding:1em;
  margin:1em;
  cursor: pointer;
}
.group-controls{
  font-size: 10px;
  color: #ccc;
  text-transform: uppercase;
  position: absolute;
  top: -18px;
}
.user-control-grid-unassigned{
  background: #1f2536;
  margin:  20px 5px 0;
  padding: 5px;
  border:1px dotted #444;
  position: relative;
  min-height: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.user-control-grid-unassigned::before{
  content:"UNASSIGNED";
  font-size: 10px;
  color: #ccc;
  text-transform: uppercase;
  position: absolute;
  left:1px;
  top: -18px;
}
.user-control-grid-recordables{
  background: #1f2536;
  margin:  40px 5px 0;
  padding: 5px;
  border:1px dotted #444;
  position: relative;
  min-height: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
/* .user-control-grid-recordables::before{
  content:"RECORDABLES";
  font-size: 10px;
  color: #ccc;
  text-transform: uppercase;
  position: absolute;
  left:1px;
  top: -18px;
} */
.user-control-group-drop{
  background: #1f2536;
  border-bottom:1px dotted #444;
  border-right:1px dotted #444;
  position: relative;
  height: 24px;
  text-align:center;
}
.user-control-group-drop::before{
  content: attr(data-order);
  font-size: 10px;
  color: #666;
  text-transform: uppercase;
  position: absolute;
  left:50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%,-50%);
  z-index: 1;
}
.user-control-group.star.active{
  color: #fff;
  border-color:#ebcc4c;
  background-color: #ebcc4c33;
}
.user-control-group.star.dropActive{
  border-color:#ebcc4c;
  background-color: #ebcc4c33;
}
.user-control-grid-unassigned.dropActive{
  background-color: #214122;
}

.user-card{
  text-transform: none;
  color: #fff;
  background-color: #364d36;
  padding: 3px 5px 3px;
  margin: 3px;
  border-radius: 3px;
  cursor: move;
  font-size: 10px;
  text-align: left;
  min-width: 130px;
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-card.rank-1,
.user-card.rank-2,
.user-card.rank-3{
  outline:2px solid #17a01a;
}
.user-card.rank-1::before,
.user-card.rank-2::before,
.user-card.rank-3::before{
  content:"1";
  position: absolute;
  font-weight: bold;
  top:-5px;
  right:-5px;
  display: block;
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  background-color: #2e602fcc;
  text-shadow:2px 3px 4px black;
  border-radius: 10px;
  border: 2px solid #fff;
}
.user-card.rank-2::before{
  content:"2";
}
.user-card.rank-3::before{
  content:"3";
}
.user-card.offline{
  background-color: #666;
} 
.user-card .name{
  padding-right:5px;
  display: inline-block;
  min-width:75px;
  white-space: nowrap;
  vertical-align: middle;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
}
.user-card .audio,
.user-card .video{
  font-size: 8px;
  background: #fffc;
}
.user-card .record{
  width: 8px;
  height: 8px;
  border-radius: 5px;
  position: absolute;
  right: 3px;
}
.user-card .record.enabled{
  background-color: #ff8181;
}
.user-card .record.disabled{
  background-color: #151a29;
}
.presence{
  text-align: center;
  background-color: #000e;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1em;
}
/* 
.episode-editor fieldset{
  min-height: 390px;
} */

.login-live{
  position: absolute;
  right: 0;
  margin: 1em;
  z-index: 1;
}

.login-live .signedin-text{
  display: inline-block;
  height: 56px;
  vertical-align: middle;
  padding-right: 1em;
}
.login-live .button{
  display: inline-block;
  width:14px;
  margin: 0;
}

.modal-buttons{
  display:flex;
  width: 400px;
  margin: 0 auto 1em;
}

.locale-swticher{
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: top;
  font-size: 20px;
}

.locale-swticher li{
  display: inline-block;
  margin: 0 5px;
}
.locale-swticher li a{
  padding: 0 10px;
}
.starVids-container{
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-wrap: wrap; */
}
.star-bg{
  /* flex-basis: 50%; */
  flex-basis: 100%;
  position: relative;
  background: center center no-repeat;
  background-size: cover;
}
/* .starVids-container.grid-count-4 .star-bg{
  flex-basis: 33.3333%;
}
.starVids-container.grid-count-1 .star-bg:first-child,
.starVids-container.template-2 .star-bg:first-child{
  flex-basis: 100%;
} */
.star-bg .vid.vid-star{
  opacity: 1;
  transition: opacity 1s ease-in-out;
}
.star-bg .agoraContainer{
  background: none;
}
.star-bg .agoraContainer.disabled{
  opacity: 1;
}
.star-bg .agoraContainer.disabled .vid.vid-star{
  opacity: 0;
}
.users__container{ 
  display: flex;
  flex-wrap: wrap;
  max-height: 100%;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: flex-start;
}
.users__container .user__card{
  cursor: pointer;
  width: 300px;
  height: 100px;
  display: flex;
  background-color: #999;
  border-radius: 3px;
  margin: 10px;
  align-items: center;
  color: #000;
}
.users__container .user__card:hover{
  background-color: #666;
}
.user__image{
  border-radius: 50%;
  overflow: hidden;
  margin: 1em;
}

video{
  pointer-events: none;
}